<template>
	<div class="view-forget-step-three">
		<div class="img-box"></div>
		<div class="title">Successful verification!</div>
		<div @click="$router.push('/home')" class="btn-box flex-c point">Back to home</div>
	</div>
</template>

<script>
	export default {
		props: {
			email: String,
			auth: String
		},
		data() {
			return {};
		},
		methods: {}
	};
</script>

<style lang="scss">
	.view-forget-step-three {
		padding: 0.8rem 7.82rem;
		box-sizing: border-box;
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		justify-content: center;
		.img-box {
			width: 1.6rem;
			height: 1.6rem;
			@include pcurl('step3-bg.png');
			margin-bottom: 0.5rem;
		}
		.title {
			width: 100%;
			margin-bottom: 0.6rem;
			font-weight: 500;
			font-size: 0.32rem;
			color: #000000;
		}
		.btn-box {
			width: 2.46rem;
			height: 0.44rem;
			background: #4182a4;
			color: #fff;
		}
	}
</style>
