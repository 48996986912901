<template>
	<div v-if="step === 1" class="view-forget-step-one">
		<div class="title">Email Address Verification</div>
		<div class="center-box">
			<div class="center-title">Email address</div>
			<div class="center-input input-box">
				<el-input v-model="email" placeholder="input"></el-input>
			</div>
			<div class="center-text">Please enter an email address that can receive product keys.</div>
			<div class="btn-box flex-c">
				<div v-if="isCheckIng" class="check-img rotate360">
					<img class="w-h-full" src="@/assets/images/check-loading.png" alt="" />
				</div>
				<div v-else-if="isCheckOver" class="check-img">
					<img class="w-h-full" src="@/assets/images/check-over.png" alt="" />
				</div>
				<div @click="checkEmailOnClick" class="w-h-full point flex-c" v-else>Verify</div>
			</div>
		</div>
	</div>
	<div v-else-if="step === 2">
		<forgetStepTwo @changeStep="changeStepEmit" :email="email" :auth="auth" />
	</div>
	<div v-else>
		<forgetStepThree :email="email" :auth="auth" />
	</div>
</template>

<script>
	import { checkEmailApi, checkEmailTimeApi } from '@/network/api.js';
	import forgetStepTwo from './forgetStepTwo.vue';
	import forgetStepThree from './forgetStepThree.vue';
	export default {
		components: {
			forgetStepTwo,
			forgetStepThree
		},
		data() {
			return {
				step: 1,
				email: '',
				auth: '',
				isCheckIng: false,
				isCheckOver: false
			};
		},
		methods: {
			changeStepEmit(step = 1) {
				this.step = step;
			},
			async checkEmailOnClick() {
				console.log('checkEmailOnClick');
				const params = {
					email: this.email,
					type: 2
				};
				const res = await checkEmailApi(params);
				if (res.code === 1) {
					this.$message.success('The verification email was successfully sent. Please go to your email to check');
					this.isCheckIng = true;
					this.Timer = setInterval(() => {
						this.checkEmailTimeFun();
					}, 3000);
					this.Timer2 = setTimeout(() => {
						this.$message.success('Verification timeout');
						clearInterval(this.Timer);
					}, 30000 * 30);
				}
				console.log('checkEmailOnClick', res);
			},
			async checkEmailTimeFun() {
				const params = {
					email: this.email,
					type: 2
				};
				const res = await checkEmailTimeApi(params);
				if (res.code === 1) {
					this.$message.success('Verification successful');
					this.isCheckIng = false;
					this.isCheckOver = true;
					clearTimeout(this.Timer2);
					clearInterval(this.Timer);
					// this.$router.push('/forgetStepTwo');
					this.email = res.data.email;
					this.auth = res.data.token;
					this.step = 2;
				}
				console.log('checkEmailOnClick', res);
			}
		}
	};
</script>

<style lang="scss">
	.view-forget-step-one {
		padding: 0.8rem 6.2rem;
		box-sizing: border-box;
		.title {
			margin-bottom: 0.6rem;
			font-weight: 500;
			font-size: 0.32rem;
			text-align: center;
		}
		.center-box {
			width: 6.8rem;
			// height: 2.5rem;
			background: #ffffff;
			box-shadow: 0px 0.06rem 0.3rem 0px rgba(0, 0, 0, 0.2), 0px -0.01rem 0.04rem 0px rgba(0, 0, 0, 0.2);
			padding: 0.39rem 0.6rem 0.31rem;
			box-sizing: border-box;
			.center-title {
				margin-bottom: 0.12rem;
				color: #333333;
			}
			.center-input {
				width: 5.6rem;
				height: 0.44rem;
				margin-bottom: 0.12rem;
				border: 0.01rem solid #b3cddb;
			}
			.center-text {
				font-size: 0.12rem;
				color: #666666;
				margin-bottom: 0.4rem;
			}
			.btn-box {
				width: 100%;
				height: 0.44rem;
				background: #4182a4;
				color: #fff;
			}
			.check-img {
				width: 0.28rem;
				height: 0.28rem;
			}
		}
	}
</style>
