<template>
	<div class="view-forget-step-two">
		<div class="title">Change password</div>
		<div class="center-box">
			<div class="center-title">Enter your email password</div>
			<div class="center-input input-box">
				<el-input v-model="password" type="password" placeholder="input"></el-input>
			</div>
			<div class="center-title">Enter your email password again</div>
			<div class="center-input input-box">
				<el-input v-model="password2" type="password" placeholder="input"></el-input>
			</div>
			<div @click="submitOnClick" class="btn-box flex-c point">
				<div>Confirm modification</div>
			</div>
		</div>
	</div>
</template>

<script>
	import { resetPasswordApi } from '@/network/api.js';
	export default {
		props: {
			email: String,
			auth: String
		},
		data() {
			return {
				password: '',
				password2: ''
			};
		},
		methods: {
			async submitOnClick() {
				// console.log('修改密码成功');
				// this.$router.push('/forgetStepThree');
				this.resetPasswordFun();
			},
			async resetPasswordFun() {
				const params = {
					email: this.email,
					newpassword: this.password,
					auth: this.auth
				};
				const res = await resetPasswordApi(params);
				console.log('修改密码成功', res);
				if (res.code === 1) {
					this.$emit('changeStep', 3);
				}
				// this.$router.push('/forgetStepThree');
			}
		}
	};
</script>

<style lang="scss">
	.view-forget-step-two {
		padding: 0.8rem 6.2rem;
		box-sizing: border-box;
		.title {
			margin-bottom: 0.6rem;
			font-weight: 500;
			font-size: 0.32rem;
			text-align: center;
		}
		.center-box {
			width: 6.8rem;
			// height: 2.5rem;
			background: #ffffff;
			box-shadow: 0px 0.06rem 0.3rem 0px rgba(0, 0, 0, 0.2), 0px -0.01rem 0.04rem 0px rgba(0, 0, 0, 0.2);
			padding: 0.39rem 0.6rem 0.31rem;
			box-sizing: border-box;
			.center-title {
				margin-bottom: 0.12rem;
				color: #333333;
			}
			.center-input {
				width: 5.6rem;
				height: 0.44rem;
				margin-bottom: 0.12rem;
				border: 0.01rem solid #b3cddb;
			}
			.center-text {
				font-size: 0.12rem;
				color: #666666;
				margin-bottom: 0.4rem;
			}
			.btn-box {
				width: 100%;
				height: 0.44rem;
				background: #4182a4;
				color: #fff;
				margin-top: 0.4rem;
			}
			.check-img {
				width: 0.28rem;
				height: 0.28rem;
			}
		}
	}
</style>
